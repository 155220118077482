/* eslint-disable indent */
import { combineReducers } from 'redux';
import {
  formatRunMethods,
  formatRunTypes,
  formatTestTypes,
  formatTestTypesMethods,
} from 'utils/analysisRules';
import actions from './actions';

const initialState = {
  test_types: [],
  sample_types: [],
  control_results: [],
  test_type__methods: {},
  runMethods: {},
  testTypesMethods: {},
  testTypes: {},
  isLoading: false,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actions.FETCH_TEST_PARAMS_REQUEST: {
      return {
        ...state,
        isLoading: true,
      };
    }
    case actions.FETCH_TEST_PARAMS_SUCCESS: {
      return {
        ...state,
        ...action.payload.data,
        isLoading: false,
      };
    }
    case actions.FETCH_TEST_PARAMS_FAILURE: {
      return {
        ...state,
        isLoading: false,
      };
    }
    case actions.FETCH_RUN_METHODS_REQUEST: {
      return {
        ...state,
        isLoading: true,
      };
    }
    case actions.FETCH_RUN_METHODS_SUCCESS: {
      const { data, isRunTemplate } = action.payload;

      return {
        ...state,
        ...data,
        runMethods: formatRunMethods(data),
        ...(isRunTemplate
          ? {
              testTypesMethods: formatTestTypesMethods(data),
              testTypes: formatTestTypes(data),
            }
          : {}),
        runTypes: formatRunTypes(data),
        isLoading: false,
      };
    }
    case actions.FETCH_RUN_METHODS_FAILURE: {
      return {
        ...state,
        isLoading: false,
      };
    }
    default:
      return state;
  }
};

const initialResultListState = {
  items: [],
  isLoading: false,
};

const resultListReducer = (state = initialResultListState, action) => {
  switch (action.type) {
    case actions.FETCH_RESULT_LIST_REQUEST: {
      return {
        ...state,
        isLoading: true,
      };
    }
    case actions.FETCH_RESULT_LIST_SUCCESS: {
      return {
        ...state,
        items: action.payload.data,
        isLoading: false,
      };
    }
    case actions.FETCH_RESULT_LIST_FAILURE: {
      return {
        ...state,
        isLoading: false,
      };
    }
    default:
      return state;
  }
};

export default combineReducers({
  resultList: resultListReducer,
  testParams: reducer,
});
